import { memo, useState, useEffect, useRef, useCallback } from "react";
import { useForm } from "@formspree/react";
import { useTranslation } from "react-i18next";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../alert/custom-toast-styles.css";

const InputField = memo(({ label, id, value, onChange, type = "text", isVisible }) => {
  const animationDelay = {
    label: '0.2s',
    input: '0.3s'
  };

  return (
    <div
      className="mb-8 flex flex-col gpu-accelerate"
      style={{
        opacity: isVisible ? 1 : 0,
        transform: `translateY(${isVisible ? '0' : '20px'})`,
        transition: 'transform 0.5s cubic-bezier(0.16, 1, 0.3, 1), opacity 0.5s ease-out'
      }}
    >
      <label
        htmlFor={id}
        className="flex text-white mb-2 gpu-accelerate"
        style={{
          opacity: isVisible ? 1 : 0,
          transform: `translateX(${isVisible ? '0' : '-20px'})`,
          transition: 'transform 0.5s cubic-bezier(0.16, 1, 0.3, 1), opacity 0.5s ease-out',
          transitionDelay: animationDelay.label
        }}
      >
        {label}
      </label>
      {type === "textarea" ? (
        <textarea
          id={id}
          name={id}
          value={value}
          onChange={onChange}
          className="w-full py-2 px-3 rounded input-focus-scale focus:outline-none focus:ring-2 
            focus:ring-lightBlue bg-white/10 transition-all duration-300"
          placeholder={label}
          required
          rows={4}
          style={{ transitionDelay: animationDelay.input }}
        />
      ) : (
        <input
          type={type}
          id={id}
          name={id}
          value={value}
          onChange={onChange}
          className="w-full py-2 px-3 rounded input-focus-scale focus:outline-none focus:ring-2 
            focus:ring-lightBlue bg-white/10 transition-all duration-300"
          placeholder={label}
          required
          style={{ transitionDelay: animationDelay.input }}
        />
      )}
    </div>
  );
});

InputField.displayName = 'InputField';

const AfaaqContactUs = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({ name: "", email: "", message: "" });
  const [state, handleSubmit] = useForm("mjvnabrp");
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1, rootMargin: '50px' }
    );

    if (sectionRef.current) observer.observe(sectionRef.current);
    return () => observer.disconnect();
  }, []);

  const notify = useCallback(() => 
    toast.success(t("alert.text"), {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    }), [t]);

  const notifyError = useCallback(() => 
    toast.error(t("alert.error"), {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    }), [t]);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  }, []);

  const clearForm = useCallback(() => {
    setFormData({ name: "", email: "", message: "" });
  }, []);

  const onSubmitForm = async (e) => {
    e.preventDefault();
    try {
      await handleSubmit(e);
      notify();
      clearForm();
    } catch (error) {
      notifyError();
    }
  };

  useEffect(() => {
    if (state.succeeded) {
      notify();
      clearForm();
    }
  }, [state.succeeded, notify, clearForm]);

  return (
    <section id="AfaaqContactUs" className="h-auto pt-4 sm:px-16 px-6 scroll-mt-20 content-defer">
      <div
        ref={sectionRef}
        className="container mx-auto p-6 sm:p-10 rounded-xl grid grid-cols-1 md:grid-cols-2 gap-4 
          bg-white/10 gpu-accelerate"
        style={{
          opacity: isVisible ? 1 : 0,
          transform: `translateY(${isVisible ? '0' : '48px'})`,
          transition: 'transform 0.6s cubic-bezier(0.16, 1, 0.3, 1), opacity 0.6s ease-out'
        }}
      >
        {/* Header Section */}
        <div className="pt-16 pb-6 sm:px-8 md:px-4 text-white md:col-span-1">
          <h1
            className="pt-6 px-8 sm:px-16 font-bold text-3xl bg-gradient-to-r from-lightBlue 
              via-green-400 to-yellow-400 text-transparent bg-clip-text gpu-accelerate"
            style={{
              opacity: isVisible ? 1 : 0,
              transform: `translateY(${isVisible ? '0' : '-20px'})`,
              transition: 'transform 0.5s cubic-bezier(0.16, 1, 0.3, 1), opacity 0.5s ease-out',
              transitionDelay: '0.2s'
            }}
          >
            {t("Contact.paragraph1")}
          </h1>
          <p
            className="pt-6 px-8 sm:px-16 gpu-accelerate"
            style={{
              opacity: isVisible ? 1 : 0,
              transform: `translateY(${isVisible ? '0' : '20px'})`,
              transition: 'transform 0.5s cubic-bezier(0.16, 1, 0.3, 1), opacity 0.5s ease-out',
              transitionDelay: '0.4s'
            }}
          >
            {t("Contact.paragraph2")}
          </p>
        </div>

        {/* Form Section */}
        <div className="container px-4 sm:px-8 md:px-12 py-6 md:col-span-1">
          <form
            name="sentMessage"
            className="w-full px-6 md:items-end space-y-6"
            onSubmit={onSubmitForm}
          >
            <InputField
              label={t("Contact.inputs.0.label")}
              id="name"
              value={formData.name}
              onChange={handleChange}
              isVisible={isVisible}
            />
            <InputField
              label={t("Contact.inputs.1.label")}
              id="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              isVisible={isVisible}
            />
            <InputField
              label={t("Contact.inputs.2.label")}
              id="message"
              type="textarea"
              value={formData.message}
              onChange={handleChange}
              isVisible={isVisible}
            />

            <div className="relative group">
              <button
                type="submit"
                disabled={state.submitting}
                className="button-hover group relative w-full py-3 px-6 text-white rounded-lg 
                  overflow-hidden bg-gradient-to-r from-lightBlue/20 via-green-400/20 
                  to-yellow-400/20 border border-lightBlue/30 gpu-accelerate"
              >
                <div className="absolute top-0 -inset-full h-full w-1/2 z-5 block transform 
                  -skew-x-12 bg-gradient-to-r from-transparent to-white/10 animate-shine" />
                <div className="absolute inset-0 rounded-lg opacity-0 group-hover:opacity-100
                  bg-gradient-to-r from-lightBlue/10 via-green-400/10 to-yellow-400/10 
                  animate-glow-border" />
                <div className="relative flex items-center justify-center gap-2">
                  <span>{state.submitting ? t("Contact.submitting") : t("Contact.btn")}</span>
                  <div className="animate-arrow">
                    {t("language.direction") === "rtl" ? (
                      <FaArrowLeft className="text-yellow-400" size={16} />
                    ) : (
                      <FaArrowRight className="text-yellow-400" size={16} />
                    )}
                  </div>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer position="top-center" transition={Zoom} />
    </section>
  );
};

export default memo(AfaaqContactUs);