import React, { useState, useEffect, useRef, memo } from 'react';
import { useTranslation } from 'react-i18next';

const TrackImage = memo(({ image, index, parentVisible }) => {
  const imageRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const animationDelay = `${index * 150}ms`; // Slightly faster delay for better UX

  // Image preloader
  const preloadImage = async (src) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        setIsLoaded(true);
        resolve();
      };
      img.src = src;
    });
  };

  useEffect(() => {
    if (parentVisible && image) {
      preloadImage(image);
    }
  }, [image, parentVisible]);

  return (
    <div
      ref={imageRef}
      className="relative overflow-hidden rounded-lg shadow-xl gpu-accelerate"
      style={{
        opacity: parentVisible ? 1 : 0,
        transform: `translateY(${parentVisible ? '0' : '50px'})`,
        transition: `transform 0.6s cubic-bezier(0.16, 1, 0.3, 1) ${animationDelay}, 
          opacity 0.6s ease-out ${animationDelay}`,
      }}
    >
      {/* Loading placeholder */}
      {!isLoaded && parentVisible && (
        <div className="absolute inset-0 bg-white/5 animate-pulse" />
      )}

      {/* Gradient Overlay */}
      <div
        className="absolute inset-0 bg-gradient-to-r from-cyan-500/20 to-blue-500/20 gpu-accelerate"
        style={{
          opacity: parentVisible && isLoaded ? 1 : 0,
          transition: `opacity 0.5s ease-out ${animationDelay}`
        }}
      />
      
      {/* Image */}
      <img 
        src={image} 
        alt={`Track ${index + 1}`} 
        className="w-full relative z-10 gpu-accelerate"
        loading={index <= 1 ? "eager" : "lazy"}
        decoding="async"
        style={{
          transform: `scale(${isLoaded && parentVisible ? '1' : '1.1'})`,
          transition: 'transform 1.2s cubic-bezier(0.16, 1, 0.3, 1)',
          transitionDelay: animationDelay
        }}
        onLoad={() => setIsLoaded(true)}
      />
    </div>
  );
});

const AfaaqTracks = () => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  const tracks = t('afaaqTracks.tracks', { returnObjects: true });

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { 
        threshold: 0.1,
        rootMargin: '100px 0px' // Preload earlier
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <section className="py-8 px-4 overflow-hidden content-defer">
      <div className="container mx-auto py-12 flex flex-col items-center">
        {/* Header Section */}
        <header
          className="text-center mb-12 gpu-accelerate"
          style={{
            opacity: isVisible ? 1 : 0,
            transform: `translateY(${isVisible ? '0' : '-20px'})`,
            transition: 'transform 0.6s cubic-bezier(0.16, 1, 0.3, 1), opacity 0.6s ease-out'
          }}
        >
          <h2 className="text-lightBlue text-3xl mb-4">
            {t('afaaqTracks.title')}
          </h2>
          <p 
            className="text-white"
            style={{
              opacity: isVisible ? 1 : 0,
              transition: 'opacity 0.5s ease-out',
              transitionDelay: '0.2s'
            }}
          >
            {t('afaaqTracks.subtitle')}
          </p>
        </header>

        {/* Tracks Grid */}
        <div 
          ref={sectionRef}
          className="grid grid-cols-1 sm:grid-cols-2 gap-6 w-full"
        >
          {tracks.map((track, index) => (
            <TrackImage 
              key={index}
              image={track.image}
              index={index}
              parentVisible={isVisible}
            />
          ))}
        </div>

        {/* Background Elements */}
        {isVisible && (
          <div 
            className="absolute inset-0 pointer-events-none"
            aria-hidden="true"
          >
            <div 
              className="absolute top-1/4 left-1/4 w-32 h-32 bg-cyan-500 rounded-full blur-3xl 
                transform gpu-accelerate"
              style={{
                opacity: 0.1,
                transition: 'opacity 1s ease-out'
              }}
            />
            <div 
              className="absolute bottom-1/4 right-1/4 w-32 h-32 bg-blue-500 rounded-full blur-3xl 
                transform gpu-accelerate"
              style={{
                opacity: 0.1,
                transition: 'opacity 1s ease-out',
                transitionDelay: '0.3s'
              }}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default AfaaqTracks;