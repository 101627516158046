import React, { useState, useEffect, useRef, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Quote } from 'lucide-react';

const TestimonialCard = memo(({ quote, description, author, index, parentVisible }) => {
  const animationDelay = `${index * 150}ms`;
  
  return (
    <div 
      className="relative w-full md:w-[400px] px-8 py-10 rounded-3xl border border-lightBlue gpu-accelerate"
      style={{
        opacity: parentVisible ? 1 : 0,
        transform: `translateY(${parentVisible ? '0' : '48px'})`,
        transition: `transform 0.6s cubic-bezier(0.16, 1, 0.3, 1) ${animationDelay}, 
          opacity 0.6s ease-out ${animationDelay}`,
      }}
    >
      {/* Quote Icon */}
      <div 
        className="absolute -top-5 left-8 gpu-accelerate"
        style={{
          opacity: parentVisible ? 1 : 0,
          transform: `scale(${parentVisible ? 1 : 0})`,
          transition: 'transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1), opacity 0.5s ease-out',
          transitionDelay: animationDelay
        }}
      >
        <div className="w-10 h-10 border border-lightBlue rotate-45 flex items-center justify-center bg-primary">
          <Quote className="text-lightBlue w-6 h-6 -rotate-45" />
        </div>
      </div>

      {/* Content */}
      <div 
        className="text-right gpu-accelerate"
        style={{
          opacity: parentVisible ? 1 : 0,
          transform: `translateY(${parentVisible ? '0' : '20px'})`,
          transition: 'transform 0.5s cubic-bezier(0.16, 1, 0.3, 1), opacity 0.5s ease-out',
          transitionDelay: `${Number(animationDelay.slice(0, -2)) + 200}ms`
        }}
      >
        <h4 className="text-lightBlue text-xl font-bold mb-4">{quote}</h4>
        <p className="text-gray-300 text-sm leading-relaxed mb-6">{description}</p>
        <p className="text-white font-bold">{author}</p>
      </div>
    </div>
  );
});

TestimonialCard.displayName = 'TestimonialCard';

const AfaaqTestimonial = () => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  const testimonials = [
    {
      quote: t('afaaqTestimonial.card1.quote'),
      description: t('afaaqTestimonial.card1.description'),
      author: t('afaaqTestimonial.card1.author')
    },
    {
      quote: t('afaaqTestimonial.card2.quote'),
      description: t('afaaqTestimonial.card2.description'),
      author: t('afaaqTestimonial.card2.author')
    },
    {
      quote: t('afaaqTestimonial.card3.quote'),
      description: t('afaaqTestimonial.card3.description'),
      author: t('afaaqTestimonial.card3.author')
    }
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { 
        threshold: 0.1,
        rootMargin: '50px'
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <section className="py-24 content-defer">
      <div className="container mx-auto px-4">
        <h2 
          ref={sectionRef}
          className="text-4xl text-center text-lightBlue font-bold mb-16 gpu-accelerate"
          style={{
            opacity: isVisible ? 1 : 0,
            transform: `translateY(${isVisible ? '0' : '20px'})`,
            transition: 'transform 0.6s cubic-bezier(0.16, 1, 0.3, 1), opacity 0.6s ease-out'
          }}
        >
          {t('afaaqTestimonial.title')}
        </h2>

        <div className="flex flex-col md:flex-row gap-8 justify-center items-stretch">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard
              key={index}
              index={index}
              {...testimonial}
              parentVisible={isVisible}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default memo(AfaaqTestimonial);