import React, { useState, useEffect, useRef, memo } from 'react';
import { useTranslation } from 'react-i18next';

const MetricCard = memo(({ metric, index, parentVisible }) => {
  const animationDelay = `${index * 100}ms`;

  return (
    <div
      className="relative gpu-accelerate"
      style={{
        opacity: parentVisible ? 1 : 0,
        transform: parentVisible ? 'translateY(0)' : 'translateY(50px)',
        transition: `transform 0.5s ease-out ${animationDelay}, opacity 0.5s ease-out ${animationDelay}`
      }}
    >
      <div 
        className="border-lightBlue border p-8 rounded-lg flex flex-col items-center relative overflow-hidden group gpu-accelerate"
        style={{
          background: parentVisible ? 'rgba(255, 255, 255, 0.03)' : 'transparent',
          transition: `background-color 0.5s ease-out ${animationDelay}`
        }}
      >
        {/* Gradient Slide Effect */}
        <div
          className="absolute inset-0 bg-gradient-to-r from-transparent via-lightBlue/10 to-transparent"
          style={{
            animation: parentVisible ? 'slideGradient 2s ease-in-out infinite' : 'none',
            animationDelay: animationDelay
          }}
        />
        
        {/* Glow Effect */}
        <div
          className="absolute inset-0 rounded-lg"
          style={{
            animation: parentVisible ? 'glowingBorder 2s ease-in-out infinite' : 'none',
            animationDelay: animationDelay
          }}
        />
        
        {/* Value */}
        <div 
          className="bg-gradient-to-b from-lightBlue via-green-400 to-yellow-400 text-transparent bg-clip-text 
            text-6xl font-extrabold mb-4 relative gpu-accelerate"
          style={{
            opacity: parentVisible ? 1 : 0,
            transform: parentVisible ? 'scale(1)' : 'scale(0.5)',
            transition: `transform 0.5s ease-out ${animationDelay}, opacity 0.5s ease-out ${animationDelay}`
          }}
        >
          {metric.value}
        </div>
        
        {/* Label */}
        <p 
          className="text-white text-2xl md:text-xl font-bold text-center relative gpu-accelerate"
          style={{
            opacity: parentVisible ? 1 : 0,
            transform: parentVisible ? 'translateY(0)' : 'translateY(5px)',
            transition: `transform 0.5s ease-out ${Number(animationDelay.slice(0, -2)) + 200}ms, 
              opacity 0.5s ease-out ${Number(animationDelay.slice(0, -2)) + 200}ms`
          }}
        >
          {metric.label}
        </p>
      </div>
    </div>
  );
});

MetricCard.displayName = 'MetricCard';

const AfaaqImpact = () => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  const metrics = t('afaaqImpact.metrics', { returnObjects: true });

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { 
        threshold: 0.1,
        rootMargin: '50px'
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <div className="py-12 px-4 relative overflow-hidden content-defer">
      {/* Background Glow */}
      <div 
        className="absolute inset-0 pointer-events-none"
        style={{
          opacity: isVisible ? 1 : 0,
          transition: 'opacity 1s ease-out',
          background: 'radial-gradient(circle at 20% 20%, rgba(0, 255, 255, 0.03) 0%, transparent 70%)'
        }}
      />
      
      <div 
        ref={sectionRef}
        className="container mx-auto py-16 flex flex-col items-center relative"
      >
        {/* Header */}
        <div className="text-center mb-12">
          <h2 
            className="text-lightBlue text-4xl font-extrabold mb-6 gpu-accelerate"
            style={{
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? 'translateY(0)' : 'translateY(-20px)',
              transition: 'transform 0.6s ease-out, opacity 0.6s ease-out'
            }}
          >
            {t('afaaqImpact.title')}
          </h2>
          <p 
            className="text-white gpu-accelerate"
            style={{
              opacity: isVisible ? 1 : 0,
              transition: 'opacity 0.5s ease-out',
              transitionDelay: '0.4s'
            }}
          >
            {t('afaaqImpact.subtitle')}
          </p>
        </div>

        {/* Metrics Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 w-full">
          {metrics.map((metric, index) => (
            <MetricCard 
              key={index} 
              metric={metric} 
              index={index}
              parentVisible={isVisible}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(AfaaqImpact);