import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import GoalCard from './GoalCard';

function AfaaqGoals() {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Disconnect once visible
        }
      },
      { 
        threshold: 0.1,
        rootMargin: '50px'
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <div className="h-auto pt-4 sm:px-16 px-6 content-defer">
      <div className="container mx-auto py-12">
        <div 
          ref={sectionRef}
          className="bg-white bg-opacity-10 p-6 rounded-xl relative overflow-hidden gpu-accelerate"
          style={{
            opacity: isVisible ? 1 : 0,
            transform: isVisible ? 'scale(1)' : 'scale(0.95)',
            transition: 'transform 0.8s ease-out, opacity 0.8s ease-out'
          }}
        >
          {/* Background gradient */}
          <div
            className="absolute inset-0 bg-gradient-to-r from-cyan-500/10 to-blue-500/10 gpu-accelerate"
            style={{
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? 'scale(1)' : 'scale(0.95)',
              transition: 'transform 1s ease-out, opacity 1s ease-out',
              willChange: 'transform, opacity'
            }}
          />

          {/* Title */}
          <h2 
            className="text-aqua-500 text-3xl mb-8 relative gpu-accelerate"
            style={{
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? 'translateX(0)' : 'translateX(-20px)',
              transition: 'transform 0.6s ease-out, opacity 0.6s ease-out',
              transitionDelay: '0.2s'
            }}
          >
            {t('afaaqGoals.title')}
          </h2>

          {/* Cards Grid */}
          <div 
            className="grid grid-cols-1 md:grid-cols-3 gap-6 relative"
          >
            {[0, 1, 2].map((index) => (
              <GoalCard
                key={index}
                icon={t(`afaaqGoals.cards.${index}.icon`)}
                description={t(`afaaqGoals.cards.${index}.description`)}
                index={index}
                isVisible={isVisible}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AfaaqGoals;