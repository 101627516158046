import React from 'react';
import Nav from '../components/Nav';
import AfaaqHero from '../components/afaaq/afaaqHero';
import AboutAfaaq from '../components/afaaq/AboutAfaaq';
import AfaaqStatistics from '../components/afaaq/AfaaqStatistics';
import AfaaqGoals from '../components/afaaq/AfaaqGoals';
import AfaaqTracks from '../components/afaaq/AfaaqTracks';
import AfaaqImpact from '../components/afaaq/AfaaqImpact';
import AfaaqTestimonial from '../components/afaaq/AfaaqTestimonial';
import AfaaqPartners from '../components/afaaq/AfaaqPartners';
import AfaaqContactUs from '../components/afaaq/afaaqContactUs'

import Footer from '../components/Footer';

const Afaaq = () => {
  return (
    <div>
      <Nav />
      <AfaaqHero />
      <AboutAfaaq />
      <AfaaqPartners />

      <AfaaqStatistics />
      <AfaaqGoals />
      <AfaaqTracks/>
      <AfaaqImpact/>
      <AfaaqTestimonial/>
      <AfaaqContactUs />

     {/* Add more content for your Afaaq page */}
      <Footer />
    </div>
  );
};

export default Afaaq;

