import React, { useState, useEffect, useRef, memo } from 'react';
import { useTranslation } from 'react-i18next';

const UniversityLogo = memo(({ filename, name, index, isVisible }) => {
  const animationDelay = `${index * 100}ms`;

  return (
    <div
      className="flex items-center justify-center p-4 hover:bg-white/10 
        transition-colors duration-300"
      style={{
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? 'scale(1)' : 'scale(0.8)',
        transition: `transform 0.6s cubic-bezier(0.16, 1, 0.3, 1), opacity 0.6s ease-out`,
        transitionDelay: animationDelay
      }}
    >
      <img
        src={`/assets/img/afaaq/client-logos/${filename}`}
        alt={name}
        className="h-16 md:h-20 w-auto opacity-70 hover:opacity-100 transition-opacity duration-300"
        loading={index <= 3 ? "eager" : "lazy"}
      />
    </div>
  );
});

UniversityLogo.displayName = 'UniversityLogo';

const AfaaqPartners = () => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  const universities = [
    {
      id: 'kfupm',
      filename: 'salogos.org-البترول-والمعادن.svg'
    },
    {
      id: 'uqu',
      filename: 'salogos.org-جامعة-أم-القرى.svg'
    },
    {
      id: 'bu',
      filename: 'salogos.org-جامعة-الباحة.svg'
    },
    {
      id: 'qu',
      filename: 'salogos.org-جامعة-القصيم.svg'
    },
    {
      id: 'ksu',
      filename: 'salogos.org-جامعة-الملك-سعود.svg'
    },
    {
      id: 'kfu',
      filename: 'salogos.org-جامعة-الملك-فيصل.svg'
    },
    {
      id: 'iaf',
      filename: 'salogos.org-جامعة-عبدالرحمن-الفيصل.svg'
    },
    {
      id: 'pnu',
      filename: 'salogos.org-جامعة-نورة.svg'
    },
    {
      id: 'kku',
      filename: 'شعار جامعة الملك خالد الجديد بدقة عالية  – SVG – png.svg'
    },
    {
      id: 'kau',
      filename: 'شعار جامعة الملك عبدالعزيز – SVG.svg'
    }
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      {
        threshold: 0.1,
        rootMargin: '50px'
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <section className="py-16 px-4 overflow-hidden content-defer relative" ref={sectionRef}>
      <div className="container mx-auto">
        {/* Header */}
        <div className="text-center mb-12">
          <h2
            className="text-lightBlue text-3xl md:text-4xl font-bold mb-4 gpu-accelerate"
            style={{
              opacity: isVisible ? 1 : 0,
              transform: `translateY(${isVisible ? '0' : '-20px'})`,
              transition: 'transform 0.6s cubic-bezier(0.16, 1, 0.3, 1), opacity 0.6s ease-out'
            }}
          >
            {t('afaaqPartners.title')}
          </h2>
          <p
            className="text-white/80 text-lg max-w-3xl mx-auto gpu-accelerate"
            style={{
              opacity: isVisible ? 1 : 0,
              transform: `translateY(${isVisible ? '0' : '20px'})`,
              transition: 'transform 0.6s cubic-bezier(0.16, 1, 0.3, 1), opacity 0.6s ease-out',
              transitionDelay: '0.2s'
            }}
          >
            {t('afaaqPartners.subtitle')}
          </p>
        </div>

        {/* Logos Grid */}
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 md:gap-6 max-w-7xl mx-auto">
          {universities.map((university, index) => (
            <UniversityLogo
              key={university.id}
              filename={university.filename}
              name={t(`afaaqPartners.universities.${university.id}`)}
              index={index}
              isVisible={isVisible}
            />
          ))}
        </div>

        {/* Background Decoration */}
        <div
          className="absolute inset-0 pointer-events-none -z-10"
          aria-hidden="true"
        >
          <div
            className="absolute top-1/4 left-1/4 w-64 h-64 bg-lightBlue rounded-full blur-3xl opacity-0"
            style={{
              opacity: isVisible ? 0.03 : 0,
              transition: 'opacity 1s ease-out'
            }}
          />
          <div
            className="absolute bottom-1/4 right-1/4 w-64 h-64 bg-lightBlue rounded-full blur-3xl opacity-0"
            style={{
              opacity: isVisible ? 0.03 : 0,
              transition: 'opacity 1s ease-out',
              transitionDelay: '0.3s'
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default AfaaqPartners;