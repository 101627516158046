import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const RhombicStat = ({ value, label, delay }) => {
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);

  return (
    <div 
      ref={elementRef}
      className={`flex flex-col items-center justify-center mb-8 md:mb-0 relative w-48 h-48 opacity-0
        ${isVisible ? 'animate-fadeInUp' : ''}`}
      style={{ animationDelay: `${delay}s` }}
    >
      <svg 
        width="192" 
        height="192" 
        viewBox="0 0 192 192" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg" 
        className="absolute top-0 left-0"
      >
        <rect 
          x="96" 
          y="0" 
          width="135.76" 
          height="135.76" 
          transform="rotate(45 96 0)" 
          stroke="#00FFFF" 
          strokeWidth="2"
          className={`${isVisible ? 'animate-pulse-slow' : ''}`}
        />
      </svg>
      <div 
        className={`text-lightBlue text-3xl md:text-3xl font-bold mb-2 scale-0
          ${isVisible ? 'animate-scaleIn' : ''}`}
        style={{ animationDelay: `${delay + 0.2}s` }}
      >
        {value}
      </div>
      <div 
        className={`text-white text-sm md:text-base text-center px-2 opacity-0
          ${isVisible ? 'animate-fadeIn' : ''}`}
        style={{ animationDelay: `${delay + 0.4}s` }}
      >
        {label}
      </div>
    </div>
  );
};

const AboutAfaaq = () => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 }
    );

    if (contentRef.current) {
      observer.observe(contentRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <div className="bg-white bg-opacity-10 py-16">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row items-center md:items-stretch justify-between">
          {/* Statistics */}
          <div className="md:w-1/2 flex flex-wrap justify-around md:justify-start md:order-1">
            <RhombicStat 
              value={t('aboutAfaaq.stat1.value')} 
              label={t('aboutAfaaq.stat1.label')} 
              delay={0}
            />
            <RhombicStat 
              value={t('aboutAfaaq.stat2.value')} 
              label={t('aboutAfaaq.stat2.label')} 
              delay={0.2}
            />
            <RhombicStat 
              value={t('aboutAfaaq.stat3.value')} 
              label={t('aboutAfaaq.stat3.label')} 
              delay={0.4}
            />
          </div>

          {/* Text content */}
          <div 
            ref={contentRef}
            className={`md:w-1/2 text-right mb-8 md:mb-0 md:order-2 md:flex md:flex-col md:justify-center
              opacity-0 translate-x-[100px] ${isVisible ? 'animate-slideInRight' : ''}`}
          >
            <h2 
              className={`text-lightBlue text-3xl font-bold mb-4 opacity-0
                ${isVisible ? 'animate-fadeInDown delay-300' : ''}`}
            >
              {t('aboutAfaaq.title')}
            </h2>
            <p 
              className={`text-white text-lg opacity-0
                ${isVisible ? 'animate-fadeInUp delay-500' : ''}`}
            >
              {t('aboutAfaaq.description')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutAfaaq;