import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const CountingNumber = ({ value, duration = 2000, isVisible }) => {
  const [count, setCount] = useState(0);
  const valueNum = parseInt(value.replace(/[^0-9]/g, ''));
  const startTimeRef = useRef(null);
  const animationFrameRef = useRef(null);
  
  useEffect(() => {
    if (!isVisible) return;
    
    const updateCount = (timestamp) => {
      if (!startTimeRef.current) startTimeRef.current = timestamp;
      const progress = timestamp - startTimeRef.current;
      
      if (progress < duration) {
        const percentage = progress / duration;
        const currentCount = Math.floor(valueNum * percentage);
        setCount(currentCount);
        animationFrameRef.current = requestAnimationFrame(updateCount);
      } else {
        setCount(valueNum);
      }
    };
    
    animationFrameRef.current = requestAnimationFrame(updateCount);
    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [valueNum, duration, isVisible]);

  return (
    <span>
      {value.startsWith('+') ? '+' : ''}{count}
    </span>
  );
};

const StatisticItem = ({ value, description, index, parentIsVisible }) => {
  const itemRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  
  useEffect(() => {
    if (!parentIsVisible) return;
    
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (itemRef.current) {
      observer.observe(itemRef.current);
    }

    return () => observer.disconnect();
  }, [parentIsVisible]);

  const animationDelay = `${index * 200}ms`;

  return (
    <div 
      ref={itemRef}
      className="mb-16 text-right transform opacity-0 translate-y-8 gpu-accelerate"
      style={{
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? 'translateY(0)' : 'translateY(2rem)',
        transition: `all 0.6s ease-out ${animationDelay}`
      }}
    >
      <div 
        className="text-8xl md:text-9xl font-bold mb-2 bg-gradient-to-b from-lightBlue via-green-400 
          to-yellow-400 text-transparent bg-clip-text transform gpu-accelerate"
        style={{
          transform: isVisible ? 'scale(1)' : 'scale(0.5)',
          transition: `transform 0.5s ease-out ${animationDelay}`
        }}
      >
        {isVisible ? <CountingNumber value={value} isVisible={isVisible} /> : value}
      </div>
      <div 
        className="text-lightBlue text-sm md:text-base transform gpu-accelerate"
        style={{
          opacity: isVisible ? 1 : 0,
          transform: isVisible ? 'translateY(0)' : 'translateY(1rem)',
          transition: `all 0.5s ease-out ${Number(animationDelay.slice(0, -2)) + 300}ms`
        }}
      >
        {description}
      </div>
    </div>
  );
};

const AfaaqStatistics = () => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, []);

  const statistics = [
    { value: "6", description: t('afaaqStatistics.trainingPaths') },
    { value: "+20", description: t('afaaqStatistics.jobOffers') },
    { value: "+200", description: t('afaaqStatistics.beneficiaries') },
    { value: "+1500", description: t('afaaqStatistics.applicants') }
  ];

  return (
    <div className="py-24 overflow-hidden content-defer" ref={sectionRef}>
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row items-center md:items-start">
          <div 
            className="w-full md:w-2/3 mb-16 md:mb-0 md:self-center md:pr-8 transform gpu-accelerate"
            style={{
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? 'translateY(0)' : 'translateY(2rem)',
              transition: 'all 0.6s ease-out'
            }}
          >
            <h3 className="text-lightBlue text-2xl md:text-3xl font-bold mb-2">
              {t('afaaqStatistics.title')}
            </h3>
            <p className="text-lightBlue text-base md:text-lg">
              {t('afaaqStatistics.subtitle')}
            </p>
          </div>

          <div className="w-full md:w-2/3 flex flex-col items-start">
            {statistics.map((stat, index) => (
              <StatisticItem 
                key={index}
                value={stat.value}
                description={stat.description}
                index={index}
                parentIsVisible={isVisible}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AfaaqStatistics;