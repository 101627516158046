import React, { memo } from 'react';

const GoalCard = memo(({ icon, description, index, isVisible }) => {
  const animationDelay = `${index * 200}ms`;

  return (
    <div
      className="flex flex-col items-center gpu-accelerate"
      style={{
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? 'translateY(0)' : 'translateY(50px)',
        transition: `transform 0.8s ease-out ${animationDelay}, opacity 0.8s ease-out ${animationDelay}`,
        willChange: 'transform, opacity'
      }}
    >
      <div className="relative mb-4">
        <div className="w-48 h-48 relative">
          <img 
            src={icon} 
            alt={description}
            className="w-full h-full object-contain rounded-lg"
            loading="lazy"
            decoding="async"
          />
        </div>
      </div>
      <p 
        className="text-white text-center gpu-accelerate"
        style={{
          opacity: isVisible ? 1 : 0,
          transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
          transition: `transform 0.5s ease-out ${Number(animationDelay.slice(0, -2)) + 300}ms, 
            opacity 0.5s ease-out ${Number(animationDelay.slice(0, -2)) + 300}ms`
        }}
      >
        {description}
      </p>
    </div>
  );
});

GoalCard.displayName = 'GoalCard';

export default GoalCard;